import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = props => (
	<StaticQuery
		query={graphql`
			query {
				images: allFile {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								fluid(maxWidth: 1920, quality: 80) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`}
		render={data => {
			const image =
				props.filename &&
				data.images.edges.find(n =>
					n.node.relativePath.includes(props.filename.toString()),
				);
			if (!image) {
				return null;
			}
			const imageFluid = image.node.childImageSharp.fluid;

			return (
				<Img
					alt={props.alt}
					className={props.className}
					fadeIn={props.fadeIn}
					fluid={imageFluid}
					style={{ ...props.style }}
				/>
			);
		}}
	/>
);
export default Image;
