import React from 'react'
import posed from 'react-pose'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Image from '../components/image'
import BannerImpact from '../components/banner-impact'

const FadeUp = posed.div({
  exit: {
    y: '100px',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: { duration: 1000 },
  },
})

const AboutPage = () => (
  <>
    <Layout>
      <SEO title="About Energi Impact project" />

      <div className="position-relative">
        <Image
          filename="about/hero-image.jpg"
          className="img-fluid"
          style={{ minHeight: '300px' }}
        />
        <div className="d-flex hero-header-about">
          <FadeUp initialPose="exit" pose="enter" className="m-auto">
            <Image
              filename="general/logo.png"
              className="img-fluid d-none d-sm-block mx-auto"
              style={{ maxWidth: '300px' }}
            />
            <h1 className="display-4 font-weight-normal text-center text-white text-shadow mt-0 mt-sm-3 mb-0">
              World’s first
              <br />
              self-funding
              <br />
              cryptocurrency
              <br />
              impact project
            </h1>
          </FadeUp>
        </div>
      </div>

      <div className="container py-5">
        <p className="h5 text-center my-4">
          We are a community of people dedicated to the betterment of all.
          Here’s more about how and why we exist.
        </p>
      </div>

      <div className="bg-green text-white py-5">
        <div className="container pt-5">
          <h2 className="">Our Purpose</h2>
          <p>
            From the moment Tommy, our director, discovered and applied the
            power of the Law of Attraction, he knew that wisdom like this could
            transform our world for the better. We are born out of the desire to
            empower the individual to live an aligned, happy, sustainable life.
            Each movement is made up of individuals, so when we change
            ourselves; we change wider society and our planet.
          </p>

          <h3 className="mt-5">How It Works</h3>
          <p>
            Through our ingenious Energi treasury system we don’t have to wait
            to take action; we don't have to raise funds, we just need to work
            out how to best spend them to better the world. Cryptocurrency has
            changed our lives, so as part of the reinvestment process of Energi;
            we are dedicated to changing the lives of everyone and anyone we can
            as well.
          </p>
          <p>Learn more about the Energi treasury system here:</p>

          <a
            href="https://www.energi.world/treasury-history/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm btn-success mt-4"
          >
            ENERGI TREASURY
          </a>

          <h2 className="mt-5">Our Vision</h2>
          <p>
            We envision a world in which no one goes without, where kindness and
            aid is given without it needing to be asked for, where the Earth is
            respected and exists in harmony with humanity, and where holistic
            wellbeing of the individual is number one.
          </p>

          <h2 className="mt-5">Our Team</h2>
          <div className="row">
            <div className="col-md-6 col-lg-4 mt-4" data-aos="fade-up-right">
              <Image
                filename="about/parker-haleen.jpg"
                alt="PARKER HALEEN"
                style={{ width: '300px', height: '380px' }}
                className="img-fluid"
              />
              <h3 className="h6 font-weight-bold mt-2 mb-1">PARKER HALEEN</h3>
              <p>Director, Energi Impact (PH)</p>
            </div>
            <div className="col-md-6 col-lg-4 mt-4" data-aos="fade-up-left">
              <Image
                filename="about/nicholas-corliss.jpg"
                alt="NICHOLAS CORLISS"
                style={{ width: '300px', height: '380px' }}
                className="img-fluid"
              />
              <h3 className="h6 font-weight-bold mt-2 mb-1">
                NICHOLAS CORLISS
              </h3>
              <p>Project Manager (NC)</p>
            </div>
            <div className="col-md-6 col-lg-4 mt-4" data-aos="fade-up-left">
              <Image
                filename="about/charles-stephensos.jpg"
                alt="CHARLES STEPHENSON"
                style={{ width: '300px', height: '380px' }}
                className="img-fluid"
              />
              <h3 className="h6 font-weight-bold mt-2 mb-1">
                CHARLES STEPHENSON
              </h3>
              <p>Project Manager (CS)</p>
            </div>
            <div className="col-md-6 col-lg-4 mt-4" data-aos="fade-up-right">
              <Image
                filename="about/caragh-fraser.jpg"
                alt="CARAGH FRASER"
                style={{ width: '300px', height: '380px' }}
                className="img-fluid"
              />
              <h3 className="h6 font-weight-bold mt-2 mb-1">CARAGH FRASER</h3>
              <p>Creative Director (CF)</p>
            </div>
            <div className="col-md-6 col-lg-4 mt-4" data-aos="fade-up-right">
              <Image
                filename="about/marcelle-visser.jpg"
                alt="MARCELLE VISSER"
                style={{ width: '300px', height: '380px' }}
                className="img-fluid"
              />
              <h3 className="h6 font-weight-bold mt-2 mb-1">MARCELLE VISSER</h3>
              <p>Associate, HR (MV)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <p className="h5 text-center my-4">
          Our team are hand picked by our president and founder; based on their
          skills, heart and integrity. Above all, we have created a community of
          people who have dedicated their lives to our collective mission.
        </p>
      </div>

      <BannerImpact />
    </Layout>
  </>
)

export default AboutPage
